<template>
  <div style="position: relative; padding-bottom: 50px; padding-bottom: 100px">
    <div style="width: 100%" v-loading="loading">
      <!-- 状态图标 -->
      <div style="position: absolute; width: 100%; text-align: right">
        <img
          v-if="Status == 0"
          src="../../assets/status0.png"
          alt=""
          :class="isPC ? 'PCIcon' : 'mobileIcon'"
        />
        <img
          v-if="Status == 1"
          src="../../assets/status1.png"
          alt=""
          :class="isPC ? 'PCIcon' : 'mobileIcon'"
        />
        <img
          v-if="Status == 2"
          src="../../assets/status2.png"
          alt=""
          :class="isPC ? 'PCIcon' : 'mobileIcon'"
        />
      </div>
      <div style="margin: 10px">
        <el-descriptions
          class="margin-top"
          title="企业往来对账单"
          :column="isPC ? 4 : 1"
          border
          :size="isPC ? 'medium ' : 'small'"
        >
          <el-descriptions-item label="公司名称"
            ><span :class="isPC ? 'desClass1' : 'desClassnoPc'"
              >北京航研射频科技有限公司</span
            ></el-descriptions-item
          >
          <el-descriptions-item label="客户名称"
            ><span :class="isPC ? 'desClass2' : 'desClassnoPc'">{{
              GetTheDataDetail.CusName ? GetTheDataDetail.CusName : title
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="日期"
            ><span :class="isPC ? 'desClass3' : 'desClassnoPc'">{{
              info.CreateTime ||
              `${new Date().getFullYear()}-${String(
                new Date().getMonth() + 1
              ).padStart(2, "0")}-${String(new Date().getDate()).padStart(
                2,
                "0"
              )}`
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="对账单号"
            ><span :class="isPC ? 'desClass4' : 'desClassnoPc'">{{
              info.Id
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="销售员"
            ><span :class="isPC ? 'desClass1' : 'desClassnoPc'">{{
              info.Sale
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="客户代码">
            <span :class="isPC ? 'desClass2' : 'desClassnoPc'">{{
              info.CusId
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item
            ><span class="desClass3"></span
          ></el-descriptions-item>
          <el-descriptions-item
            ><span class="desClass4"></span
          ></el-descriptions-item>

          <el-descriptions-item label="联系电话"
            ><span :class="isPC ? 'desClass1' : 'desClassnoPc'">{{
              info.SalePhone
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="联系人"
            ><span :class="isPC ? 'desClass2' : 'desClassnoPc'">{{
              info.LinkMan
            }}</span>
          </el-descriptions-item>

          <el-descriptions-item
            ><span class="desClass3"></span
          ></el-descriptions-item>
          <el-descriptions-item
            ><span class="desClass4"></span
          ></el-descriptions-item>

          <el-descriptions-item label="账款截止日期"
            ><span :class="isPC ? 'desClass1' : 'desClassnoPc'">{{
              GetTheDataDetail.EndDate
                ? GetTheDataDetail.EndDate.substring(0, 10)
                : ""
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="贵公司欠我公司"
            ><span :class="isPC ? 'desClass1' : 'desClassnoPc'">
              {{
                moneyFormat(
                  Number((info.Money0 || "0").replace(/,/g, "")) +
                    Number((info.Money1 || "0").replace(/,/g, ""))
                )
              }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item
            ><span class="desClass2"></span
          ></el-descriptions-item>
          <el-descriptions-item
            ><span class="desClass3"></span
          ></el-descriptions-item>
          <!-- <el-descriptions-item
            ><span class="desClass4"></span
          ></el-descriptions-item> -->

          <el-descriptions-item label="其中：已开票未付款金额"
            ><span :class="isPC ? 'desClass2' : 'desClassnoPc'">
              {{
                moneyFormat(
                  Number((info.Money3 + "" || "0.00").replace(/,/g, ""))
                )
              }}
            </span></el-descriptions-item
          >
          <el-descriptions-item label="其中：已出库未开票金额"
            ><span :class="isPC ? 'desClass4' : 'desClassnoPc'">
              {{ info.Money1 }}
            </span></el-descriptions-item
          >

          <el-descriptions-item label="预付款">
            <span>
              {{
                moneyFormat(
                  Number((info.Money2 + "" || "0.00").replace(/,/g, ""))
                )
              }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item v-if="isPC"><span></span></el-descriptions-item>
        </el-descriptions>

        <!-- 表格区域 -->
        <div style="width: 100%; text-align: center; line-height: 25px">
          感谢贵司对我司的支持与认可！为使贵我双方业务往来数据清晰无误，例行对账，明细如下:
        </div>
        <div>
          <div
            style="
              width: 100%;
              text-align: center;
              margin: 20px 0 10px 0;
              line-height: 25px;
            "
          >
            已开票未付款明细
          </div>
          <el-table
            :data="Tabledata"
            border
            style="width: 100%; color: #000"
            size="mini"
          >
            <el-table-column
              v-for="(im, ix) in columns"
              :key="ix"
              :prop="im.dataIndex"
              :label="im.title"
              :show-overflow-tooltip="true"
              :fixed="im.fixed"
              :width="im.width || '150'"
              :align="im.align || 'center'"
              :formatter="im.formatter"
            >
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div
            style="
              width: 100%;
              text-align: center;
              margin: 20px 0 10px 0;
              line-height: 25px;
            "
          >
            已出库未开票明细
          </div>
          <el-table :data="Tabledata_o" border style="width: 100%" size="mini">
            <el-table-column
              v-for="(im, ix) in columns_o"
              :key="ix"
              :prop="im.dataIndex"
              :label="im.title"
              :show-overflow-tooltip="true"
              :fixed="im.fixed"
              :width="im.width || '150'"
              :align="im.align || 'center'"
              :formatter="im.formatter"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- text-align: right; margin-bottom: 10px; margin-top: 10px -->
      <!-- <el-pagination style="text-align: right; margin-bottom: 10px; margin-top: 10px" small background
        @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Pageindex"
        :page-sizes="[50, 100, 150, 200]" :page-size="PageRow" :pager-count="5" :layout="isPC ? 'total, sizes, prev, pager, next, jumper' : 'prev, pager, next'
          " :total="total">
      </el-pagination> -->
    </div>
    <!-- <el-dialog
      title="请选择对账日期"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <el-date-picker
        style="width: 50%; margin-left: 25%"
        v-model="time"
        type="date"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      >
      </el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getdataList">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- <el-dialog
      title="请使用微信扫一扫"
      :visible.sync="dialogVisible"
      width="300px"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
    >
      <div style="display: flex; justify-content: center">
        <vue-qr
          :logoSrc="require('@/assets/logo.png')"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </el-dialog> -->
    <!-- 签字区域 -->

    <div style="text-align: left; margin: 10px 0">
      本对账函仅为复核账目之用，并不是催款或还款结算。
      恳盼您在收到本对账函的10天之内签章确认回传。
      若您收函后未作任何沟通或说明而未按时回函，
      则以本对账函上所列示的金额为准， 您无异议予以认可。
      再次感谢您的支持与信任，期待与您的合作越来越好！
    </div>
    <!-- <el-descriptions class="margin-top" :column="isPC ? 3 : 1" border :size="isPC ? 'medium ' : 'small'">
        <el-descriptions-item label="已开票未付款金额：">{{
          toMoney(info.InvoicedButAmount)
          }}</el-descriptions-item>
        <el-descriptions-item label="已发货未开票金额：">{{
          toMoney(info.ShippedButAmount)
          }}</el-descriptions-item>
        <el-descriptions-item label="期末应收金额合计：">{{
          toMoney(info.EndingAmount)
          }}</el-descriptions-item>
      </el-descriptions> -->
    <!-- <div style="height: 50px"></div>
      <el-dialog title="确认对账函" :visible="UpVisible" width="80%">
        <div style="display: flex; width: 100%; flex-wrap: wrap">
          <div style="
              display: flex;
              justify-content: center;
              width: 50%;
              margin-bottom: 15px;
            " v-for="(item, index) in imgList" :key="index">
            <el-image style="width: 100px; height: 100px" :src="item.url" :preview-src-list="[item.url]">
            </el-image>
          </div>

          <div style="
              display: flex;
              justify-content: center;
              width: 50%;
              margin-bottom: 15px;
            ">
            <el-button icon="el-icon-plus" style="width: 100px; height: 100px" @click="openCamera">
            </el-button>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="UpVisible = false">取 消</el-button>
          <el-button type="primary" @click="UpVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div style="
          position: fixed;
          bottom: 0px;
          right: 20px;
          z-index: 999;
          width: 100%;
          height: 50px;
          background-color: #fff;
          text-align: right;
        "> -->
    <!-- <el-button
          type="primary"
          @click="dialogVisibleCustomerPhone = true"
          v-if="isshare"
          >短信分享</el-button
        >
        <el-button type="primary" @click="dialogVisible = true" v-if="isshare"
          >微信分享</el-button
        >
        <el-button type="primary" @click="UpVisible = true" v-if="!isshare"
          >确认</el-button
        > -->
    <!-- <el-button type="danger " v-if="isshare">拒收</el-button> -->
    <!-- </div> -->
    <div style="display: flex; justify-content: space-around">
      <span
        style="
          width: 35%;
          border: 1px solid #000;
          border-bottom: 0;
          padding: 5px 0;
        "
        >客户信息确认无误签章</span
      >
      <span
        style="
          width: 35%;
          border: 1px solid #000;
          border-bottom: 0;
          padding: 5px 0;
        "
        >信息不符，请列明不符内容签章</span
      >
      <span
        style="
          width: 30%;
          border: 1px solid #000;
          border-bottom: 0;
          padding: 5px 0;
        "
        >北京航研射频科技有限公司（签章）</span
      >
    </div>
    <div style="display: flex; justify-content: space-around">
      <div
        style="
          width: 35%;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          position: relative;
        "
      >
        <span style="margin: 10px 10px; text-align: left">经办人：</span>
        <span style="margin-bottom: 10px">日期：</span>
        <img class="name-sign" v-if="Status === 1" :src="Sign" alt="" />
      </div>
      <div
        style="
          width: 35%;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          position: relative;
        "
      >
        <span style="margin: 10px 10px; text-align: left">经办人：</span>
        <span style="margin-bottom: 10px">日期：</span>
        <img class="name-sign" v-if="Status === 2" :src="Sign" alt="" />
      </div>
      <div
        style="
          width: 30%;
          border: 1px solid #000;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <span>日期：</span>
      </div>
    </div>

    <div class="sign-box">
      <div>
        <el-divider></el-divider>
        <div class="title">签收照片/视频</div>
        <updataImg
          :canEdit="Status == 0"
          :imgListParam="Imgs_back"
          :videoParam="Video_back"
          @change="updataImgUrl"
        ></updataImg>
      </div>
      <div v-if="Status == 0">
        <el-divider></el-divider>
        <div class="title">签收人</div>
        <nameSign @setBase64Data="setBase64Data" :name="'签名'"></nameSign>
      </div>
    </div>

    <!-- 推送二维码 -->
    <el-dialog
      title="推送"
      :visible.sync="dialogVisible"
      width="300px"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
    >
      <div style="display: flex; justify-content: center">
        <vue-qr
          :logoSrc="require('@/assets/logo.png')"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </el-dialog>
    <el-dialog
      title="发送对账单"
      :visible.sync="dialogVisibleCustomerPhone"
      width="30%"
      :before-close="
        () => {
          this.dialogVisibleCustomerPhone = false;
        }
      "
      append-to-body
    >
      <el-input
        v-model="CustomerPhone"
        placeholder="请输入客户手机号"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCustomerPhone = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirmCustomerPhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="推送"
      :visible.sync="dialogPush"
      width="30%"
      :before-close="
        () => {
          this.dialogPush = false;
        }
      "
      append-to-body
    >
      <div style="display: flex; justify-content: center; align-items: center">
        <QRCode :text="text" :width="200" :height="200" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPush = false">关 闭</el-button>
      </span>
    </el-dialog> -->
    <!-- 只有从pc段查看详情过来不显示同意不同意按钮 其余条件均显示该按钮条件 -->
    <!-- 只有待确认条件下显示 -->
    <div
      v-if="Status == 0"
      style="
        text-align: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        padding: 20px;
      "
    >
      <el-button
        type="primary"
        @click="submitClick(1)"
        :loading="loading"
        style="width: 150px"
        >同意</el-button
      >
      <el-button
        type="primary"
        style="margin-left: 10px; width: 150px"
        @click="submitClick(2)"
        :loading="loading"
        >驳回</el-button
      >
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import wx from "weixin-js-sdk";
import moment from "moment";
// import QRCode from "../components/qrcode.vue";
import updataImg from "./../../components/updataImg.vue";
import nameSign from "./../../components/nameSign.vue";

export default {
  components: {
    VueQr,
    // QRCode,
    updataImg,
    nameSign,
  },
  computed: {},
  data() {
    return {
      isShow: false,
      dialogVisibleCustomerPhone: false,
      CustomerPhone: "",
      UpVisible: false,
      dialogVisible: false,
      dialogPush: false,
      moment,
      sendId: "",
      text: "",
      innerWidth: 0,
      isPC: false,
      codeValue: "",
      codePushValue: "",
      centerDialogVisible: true,
      isview: false,
      loading: false,
      time: "",
      title: "",
      info: {},
      Pageindex: 1,
      PageRow: 50,
      total: 0,
      columns: [
        {
          title: "开票日期",
          dataIndex: "Item0",
          width: 100,
          formatter: (row, column, cellValue) => {
            if (cellValue) {
              return moment(cellValue).format("YYYY-MM-DD");
            }
          },
        },
        { title: "单据编号", dataIndex: "Item1" },
        { title: "发票编号", dataIndex: "Item2" },
        { title: "发票类型", dataIndex: "Item3" },
        { title: "HY合同号", dataIndex: "Item4" },
        {
          title: "开票金额",
          dataIndex: "Item5",
          formatter: (row, column, cellValue) => {
            return this.moneyFormat(cellValue);
          },
        },
        { title: "收款日期", dataIndex: "Item6" },
        {
          title: "已收金额",
          dataIndex: "Item7",
          formatter: (row, column, cellValue) => {
            return this.moneyFormat(cellValue);
          },
        },
        {
          title: "应收金额",
          dataIndex: "Item8",
          formatter: (row, column, cellValue) => {
            return this.moneyFormat(cellValue);
          },
        },
        { title: "对账说明", dataIndex: "Item9" },
        { title: "发票接收人", dataIndex: "Item10" },
        { title: "客户合同编码", dataIndex: "Item11" },
        { title: "备注", dataIndex: "Item12" },
      ],
      columns_o: [
        {
          title: "发货日期",
          dataIndex: "Item0",
          width: 100,
          formatter: (row, column, cellValue) => {
            return moment(cellValue).format("YYYY-MM-DD");
          },
        },
        { title: "单据编号", dataIndex: "Item1" },
        { title: "客户商品名称", dataIndex: "Item2" },
        { title: "规格型号", dataIndex: "Item3" },
        { title: "HY合同号", dataIndex: "Item4" },
        { title: "单位", dataIndex: "Item5" },
        {
          title: "应发数量",
          dataIndex: "Item6",
          formatter: (row, column, cellValue) => {
            return Number(cellValue).toFixed(4);
          },
        },
        {
          title: "实发数量",
          dataIndex: "Item7",
          formatter: (row, column, cellValue) => {
            return Number(cellValue).toFixed(4);
          },
        },
        {
          title: "销售单价",
          dataIndex: "Item8",
          formatter: (row, column, cellValue) => {
            return this.moneyFormat(cellValue);
          },
        },
        {
          title: "销售金额",
          dataIndex: "Item9",
          formatter: (row, column, cellValue) => {
            return this.moneyFormat(cellValue);
          },
        },
        { title: "货物接收人", dataIndex: "Item10" },
        { title: "客户合同编码", dataIndex: "Item11" },
        { title: "备注", dataIndex: "Item12" },
      ],
      Tabledata: [],
      Tabledata_o: [],
      isshare: false,
      imgList: [],
      sendMessage: "[北京航研射频科技有限公司]向您发送往来对账单,请确认",
      // 判断显示同意不同意
      Status: 1,
      GetTheDataDetail: {},

      Sign: "", //签字
      Imgs: "", //图片 type string
      Video: "", //视频
      Imgs_back: "", //返回的图片
      Video_back: "", //返回的视频
    };
  },
  async created() {
    // 修改分享后的页面最顶部标题
    document.title = "";
    document.title = "北京航研射频科技有限公司";

    console.log("打印顶部标题是否改过来", document.title);
    this.getShareInfo();
    // if (this.$route.query.token) {
    //   sessionStorage.setItem("token", this.$route.query.token);
    // }

    console.log("获取行研分享过来的参数-----------", this.$route.query);
    if (this.$route.query.Id) {
      this.bcid = this.$route.query.Id;
    }
    this.$route.query.time && (this.time = this.$route.query.time);
    this.$route.query.title && (this.title = this.$route.query.title);
    // this.isshare = false;
    // // if (this.$route.query.time && this.$route.query.bcid) {
    // this.centerDialogVisible = false;

    // this.isshare = true;
    // 从新获取一遍列表判断当前行状态数据是否显示同意不同意按钮逻辑
    // 重新获取分享过来的行研对账单详情回显
    this.getdataList();
    // }
  },
  watch: {
    innerWidth(v) {
      console.log(v);
      if (v < 500) {
        this.isPC = false;
      } else {
        this.isPC = true;
      }
    },
  },
  mounted() {
    this.innerWidth = window?.innerWidth;
    if (this.innerWidth < 500) {
      this.isPC = false;
    } else {
      this.isPC = true;
    }
    window.addEventListener("resize", () => {
      this.innerWidth = window?.innerWidth;
      if (this.innerWidth < 500) {
        this.isPC = false;
      } else {
        this.isPC = true;
      }
    });
  },

  methods: {
    // 同意还是不同意
    submitClick(type) {
      if (!this.Sign) {
        this.$message.warning("请填写签名");
        return;
      }
      console.log("同意还是不同意", type, this.info, this.info.Id);
      this.loading = true;
      this.$http
        .post("/HY/hy_bill/EditState", {
          Imgs: this.Imgs,
          Video: this.Video,
          Sign: this.Sign,
          Id: this.bcid,
          State: type,
        })
        .then((res) => {
          if (res.Success) {
            this.isShow = true;
            this.Status = false;
            this.loading = false;
            if (type == 1) {
              this.$message({
                type: "success",
                message: "已同意",
              });
            } else {
              this.$message({
                type: "error",
                message: "已驳回",
              });
            }
            // 刷新详情
            this.getdataList();
          } else {
            this.$message({
              type: "error",
              message: res.Msg,
            });
          }
        });
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://hzq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        // appId: "wxa24101ce1f340e47",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "码上签",
          desc: this.sendMessage,
          link: location.href,
          imgUrl: "https://www.yoojet.com/hy.jpg",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
    blck() {
      this.$router.go(-1);
    },

    dialogConfirmCustomerPhone() {
      this.dialogVisibleCustomerPhone = false;
    },
    moneyFormat(value) {
      if (!value && value != 0) {
        return "";
      }
      // 获取元素的文本内容
      const originalValue = parseFloat(Number(value).toFixed(2));
      // 使用toLocaleString方法将数字格式化为千分位表示
      let res = originalValue.toLocaleString();
      const match = res.match(/\.(\d+)/);
      if (match && match[1].length == 1) {
        // 如果找到匹配小数点后面的数字的长度加0   /'22.4'+0
        return (res += "0");
      } else if ((match && match[1].length == 0) || !match) {
        return (res += ".00");
      } else {
        // 如果没有匹配小数部分长度，返回原数字。
        return res;
      }
    },
    // 获取详情
    getdataList() {
      this.info = {};
      this.loading = true;
      this.$http
        .post("/HY/hy_bill/GetTheData", {
          id: this.$route.query.Id,
        })
        .then((res) => {
          if (res.Success) {
            this.GetTheDataDetail = res.Data;
            if (res.Data !== null) {
              // 判断状态
              this.Status = res.Data.State;
              this.Imgs_back = res.Data.Imgs; // 返回的图片
              this.Video_back = res.Data.Video; //返回的视频
              this.Sign = res.Data.Sign;
              this.info = JSON.parse(res.Data.Content0);
              this.Tabledata = JSON.parse(res.Data.Content1);
              this.Tabledata_o = JSON.parse(res.Data.Content2);
              //预付款 已开票未付款金额
              let num = 0;
              this.Tabledata.forEach((item) => {
                if (item && item.Item8 && !isNaN(parseFloat(item.Item8))) {
                  //变成两位数
                  let item8 = Number(parseFloat(item.Item8).toFixed(2));
                  num = Number((num + item8).toFixed(2));
                }
              });
              this.info.Money2 = Number(this.info.Money0) - num;
              this.info.Money3 = num;
              this.info.CusId = res.Data.CusId;
            }
          }
          this.loading = false;
        });
    },
    setBase64Data(url) {
      this.Sign = url;
    },
    updataImgUrl({ video, imgs }) {
      this.Imgs = imgs || "";
      this.Video = video || "";
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-descriptions__header {
  justify-content: center;
}

.mobileIcon {
  width: 25vw !important;
}
.PCIcon {
  width: 6vw !important;
}
.desClass1 {
  display: inline-block;
  width: 15vw;
}

.desClass2 {
  display: inline-block;
  width: 15vw;
}

.desClass3 {
  display: inline-block;
  width: 6vw;
}

.desClass4 {
  display: inline-block;
  width: 6vw;
}

.desClassnoPc {
  display: inline-block;
  width: 48vw;
}

.sign-box {
  padding: 10px;
  .title {
    font-size: 15px;
    font-weight: bold;
  }
}

.name-sign {
  transform: rotate(-90deg);
  transform-origin: left top;
  position: absolute;
  left: 48px;
  top: 50px;
  width: 50px;
}
</style>
