<template>
  <div>
    <div class="name-button" @click="dialogVisible = true">
      <!-- <a-button @click="dialogVisible = true" type="primary">签名</a-button> -->
      <img v-if="dataUrl" :src="dataUrl" class="base-img" alt="" />
      <div v-else>请签名</div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      fullscreen
      :showClose="false"
      :before-close="dialogBeforeClose"
    >
      <div class="sign-popup-box">
        <div class="sign-content" ref="signContent">
          <div class="sign-box">
            <div
              class="bg-name"
              :style="{ fontSize: `calc(100vw / ${name.length})` }"
            >
              <span class="name" v-for="(item, inx) in name" :key="inx">{{
                item
              }}</span>
            </div>
            <div class="draw-box" ref="drawBox">
              <canvas
                ref="myCanvas"
                @mousedown="startDrawing"
                @mousemove="draw"
                @mouseup="endDrawing"
                @touchstart="startDrawing"
                @touchmove="draw"
                @touchend="endDrawing"
              ></canvas>
            </div>
          </div>
        </div>
        <div class="sign-footer">
          <a-button @click="dialogClose">取消</a-button>
          <a-button @click="clearCanvas" type="info">清空</a-button>
          <a-button type="primary" :loading="loading" @click="save">
            确定
          </a-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["name"],
  data() {
    return {
      loading: false,
      dialogVisible: false,
      ctx: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      pixRatio: 3,
      dataUrl: "", //base 64
    };
  },
  watch: {
    dialogVisible(e) {
      if (e && !this.dataUrl) {
        this.$nextTick(() => {
          this.initCanvas();
          this.clearCanvas();
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.initCanvas);
    // 上传图片，video,要实时拍摄 TODO 视频质量压缩 或时长限制
    // 样式
    // 禁止横屏
  },
  destroyed() {
    window.removeEventListener("resize", this.initCanvas);
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initCanvas();
    //   this.clearCanvas();
    // });
  },
  methods: {
    dialogBeforeClose() {},
    dialogClose() {
      this.dialogVisible = false;
    },
    dialogOpen() {
      this.dialogVisible = true;
    },
    save() {
      // this.loading = true;
      this.getBase64();
      // TODO:
      // 这里将图片传到后端 成功后 base 上传并关闭弹窗
      this.$emit("setBase64Data", this.dataUrl);
      this.dialogClose();
    },

    initCanvas() {
      try {
        let box = this.$refs.drawBox;
        let canvas = this.$refs.myCanvas;
        this.pixRatio = window.devicePixelRatio || 3;

        canvas.width = box.offsetWidth * this.pixRatio;
        canvas.height = box.offsetHeight * this.pixRatio;
        const width = canvas.width;
        const height = canvas.height;

        this.ctx = canvas.getContext("2d");
        //设置画笔样式
        this.ctx.lineWidth = 10;
        // 设置线条的端点样式
        this.ctx.lineCap = "round";
        // 设置线条的交点样式
        this.ctx.lineJoin = "round";
      } catch (error) {}
    },

    startDrawing(e) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = this.getMousePosition(e);
    },
    draw(e) {
      if (!this.isDrawing) return;
      const [x, y] = this.getMousePosition(e);
      this.ctx.beginPath();
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
      [this.lastX, this.lastY] = [x, y];
    },
    endDrawing() {
      this.isDrawing = false;
    },
    getMousePosition(e) {
      const rect = this.$refs.myCanvas.getBoundingClientRect();
      if (e.type.includes("touch")) {
        return [
          this.pixRatio * e.touches[0].clientX - this.pixRatio * rect.left,
          this.pixRatio * e.touches[0].clientY - this.pixRatio * rect.top,
        ];
      } else {
        return [
          this.pixRatio * e.clientX - this.pixRatio * rect.left,
          this.pixRatio * e.clientY - this.pixRatio * rect.top,
        ];
      }
    },
    clearCanvas() {
      const canvas = this.$refs.myCanvas;
      const context = canvas.getContext("2d");
      context.clearRect(
        0,
        0,
        canvas.width * this.pixRatio,
        canvas.height * this.pixRatio
      );
    },
    getBase64() {
      let url = this.$refs.myCanvas.toDataURL("image/png");
      this.dataUrl = this.handleBase64Data(url);
    },
    handleBase64Data(url) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isIOS) {
        //这个是ios操作系统
        // url = url.replace("data:image/png;base64,", "");
      }
      return url;
    },
  },
};
</script>
<style scoped lang="less">
.name-button {
  max-width: 400px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  border: 1px solid silver;
  position: relative;
}
canvas {
  width: 100%;
  height: 100%;
}
.sign-popup-box {
  padding: 50px 20px 50px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .sign-content {
    flex: 1;
    .sign-box {
      display: flex;
      width: 100%;
      height: calc(100% - 30px);
      border: 3px solid #eee;
      border-radius: 10px;
      border-style: dashed;
      position: relative;
    }
  }
}

.draw-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bg-name {
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name {
  transform: rotate(90deg);
  width: auto;
  text-align: center;
  color: #eee;
}
.sign-footer {
  flex-shrink: 1;
  text-align: end;
  .ant-btn {
    margin-left: 10px;
  }
}
.base-img {
  transform: rotate(-90deg);
  transform-origin: left top;
  position: absolute;
  left: 10px;
  top: 200px;
  width: 200px;
}
</style>

<!-- this.canvasCtx = uni.createCanvasContext('canvas_sign', this) //创建绘图对象
			//设置画笔样式
			this.canvasCtx.lineWidth = 4
			// 设置线条的端点样式
			this.canvasCtx.lineCap = 'round'
			// 设置线条的交点样式
			this.canvasCtx.lineJoin = 'round' -->
